@import "minimal-mistakes";

.btn--home {
    background-color:#70b9c5;
    color:#fff
}

.btn--home:visited {
    background-color:#70b9c5;
    color:#fff
}

.btn--home:hover {
    background-color:#70b9c5;
    color:#fff
}

.btn--space {
    padding-left: 200px;
}

.a_contact {
    color: #007e87;
}

.a_contact:visited {
    color: #007e87;
}

.a_contact:hover {
    color: #70b9c5;
}

div.image_right {
    float: right;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 5px;
    margin-top: 9px;
    margin-bottom: 25px;
    margin-left: 20px;
    margin-right: 0px;
    text-align: center;
}
